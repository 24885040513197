import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const SearchingSuggestion = (props) => {
  const {
    heading,
    supportingLine,
    data,
    isSearchPage,
    setSearchValue,
    setSearchResTitle,
    setNewInputVal,
    setSearchResDes,
    setSelectedValues,
  } = props;
  const navigate = useNavigate();
  const handleLinkClick = (item) => {
    const queryParams = `query=${item?.query}&searchtype=Location${
      item?.building ? `&building=${item?.building}` : ""
    }`;

    if (isSearchPage) {
      setSearchValue(item?.query);
      setSearchResTitle("Location");
      setNewInputVal(item?.query);
      setSearchResDes(item?.query);
      window.scrollTo(0, 0);
      if (item?.building) {
        setSelectedValues([item?.building]);
      }
    } else {
      navigate(`/search-listings?${queryParams}`);
    }
  };

  return (
    <div className={"conatiner searchingSuggestion_container"}>
      <h2>{heading}</h2>

      <h6 className="m-0 mt-1">{supportingLine}</h6>

      <div className="row mt-3">
        {data?.map((item, index) => {
          const to = isSearchPage
            ? `?query=${item?.query}&searchtype=Location&${
                item?.building ? `&building=${item?.building}` : ""
              }`
            : `search-listings?query=${item?.query}&searchtype=Location${
                item?.building ? `&building=${item?.building}` : ""
              }`;
          return (
            <div
              className={isSearchPage ? "col-12" : "col-md-4 col-12"}
              key={index}
            >
              <p
                className="Details_SearchesLink PropertyDetails_Mbl_f14"
                onClick={() => handleLinkClick(item)}
              >
                {item?.label}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchingSuggestion;
