import {
  CardContent,
  CardMedia,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import React from "react";
import "./PopUpCrad.css";
import BathtubIcon from "@mui/icons-material/Bathtub";
import { useState, useEffect } from "react";
import { IoBed } from "react-icons/io5";
import { FaVectorSquare, FaParking } from "react-icons/fa";
import baseURL from "../../../config";
import "../MapandListing/CardMain.css";

export default function PopUpCard({ record }) {
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const nowAssignmentPrice =
    record?.assignmentPrices?.length > 0
      ? record?.assignmentPrices[record?.assignmentPrices?.length - 1].prices
      : record?.assignmentPrice;
  const WasSqftPrice = Math.ceil(
    record?.assignmentPrice / record?.interiorSize
  );
  const NowSqftPrice = Math.ceil(nowAssignmentPrice / record?.interiorSize);
  const [bedValue, setBedValue] = useState();

  function formatClosingDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  useEffect(() => {
    if (record?.bed === "0B") {
      setBedValue("Studio");
    } else if (record?.bed === "1B") {
      setBedValue("1 Bed");
    } else if (record?.bed === "1B+D") {
      setBedValue("1 Bed + D");
    } else if (record?.bed === "2B") {
      setBedValue("2 Bed");
    } else if (record?.bed === "2B+D") {
      setBedValue("2 Bed + D");
    } else if (record?.bed === "3B") {
      setBedValue("3 Bed");
    } else if (record?.bed === "3B+D") {
      setBedValue("3 Bed+D");
    } else if (record?.bed === "4B") {
      setBedValue("4 Bed");
    } else if (record?.bed === "4B+D") {
      setBedValue("4 Bed+D");
    } else if (record?.bed === "5B") {
      setBedValue("5 Bed");
    } else if (record?.bed === "5B+D") {
      setBedValue("5 Bed+D");
    } else if (record?.bed === "6B") {
      setBedValue("6 Bed");
    } else if (record?.bed === "6B+D") {
      setBedValue("6 Bed+D");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CardMedia>
        <ImageListItem>
          <div className="mapcard_img">
            {record?.assignmentMedias &&
            record?.assignmentMedias.length > 0 &&
            record?.assignmentMedias.fileUrl !== "" ? (
              <img
                src={`${baseURL}/${record?.assignmentMedias[0]?.fileUrl}`}
                alt={record?.projectName}
                style={{ width: "100%", height: "100%", borderRadius: 8 }}
                loading="lazy"
              />
            ) : (
              <img
                src={require("../../../images/no-image.png")}
                alt={record?.projectName}
                style={{ width: "100%", height: "100%", borderRadius: 15 }}
                loading="lazy"
              />
            )}

            <ImageListItemBar
              className="p-0 listwidth"
              style={{
                padding: "5px",
                width: "97%",
                height: "170px",
                top: 5,
                left: 5,
                background:'rgba(0, 0, 0, 0.25)'
              }}
              actionIcon={
                <CardContent
                  className="p-0"
                  sx={{
                    color: " rgba(255, 255, 255, 0.87)",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      bottom: 5,
                      left: 0,
                      fontFamily: "Montserrat",
                    }}
                  >
                    <div className="Image_Overlaysetting">
                      <div className="d-flex justify-content-center align-items-center">
                        <IoBed style={{ fontSize: "14px", color: "#ffff" }} />
                        {record?.bed && record?.bed !== undefined ? (
                          <p className="card_abitem m-0">{`${bedValue}`}</p>
                        ) : (
                          <p className="card_abitem m-0">Bed</p>
                        )}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <BathtubIcon
                          sx={{ fontSize: "14px", color: "#ffff" }}
                        />
                        {record?.bath && record?.bath !== undefined ? (
                          <p className="card_abitem m-0">
                            {`${record?.bath} Bath`}{" "}
                          </p>
                        ) : (
                          <p className="card_abitem m-0">Bath</p>
                        )}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <FaVectorSquare
                          style={{ fontSize: "14px", color: "#ffff" }}
                        />
                        {record?.interiorSize &&
                        record?.interiorSize !== undefined ? (
                          <p className="card_abitem m-0">
                            {`${record?.interiorSize?.toLocaleString(
                              "en-US"
                            )} Sqft`}{" "}
                          </p>
                        ) : (
                          <p className="card_abitem m-0">Sqft</p>
                        )}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <FaParking
                          style={{ fontSize: "14px", color: "#ffff" }}
                        />
                        {record?.parking !== undefined ? (
                          <p className="card_abitem m-0">
                            {`${record?.parking}`}{" "}
                          </p>
                        ) : (
                          <p className="card_abitem m-0">0</p>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              }
            />

            <div
              style={{
                position: "absolute",
                top: 10,
                right: 8,
              }}
            >
              <button className="btn btndecor">ID: {record?.id}</button>
            </div>

            <div
              style={{
                position: "absolute",
                top: 10,
                left: 6,
              }}
            >
              {record?.dailyUpdate && (
                <>
                  <button className="dealdecor">{record?.dailyUpdate}</button>
                </>
              )}
            </div>
          </div>
        </ImageListItem>
      </CardMedia>
      <CardContent className="maindataxx popcard_maintext font p-1 ">
        <div className="typeandDate_card">
          <div>
            {" "}
            {record?.type && (
              <>
                <button className="type_btn">{record?.type}</button>
              </>
            )}
          </div>

          <div className="d-flex flex-column aligncenter w-auto">
            <div>
              {" "}
              <p className="m-0 cardMain_date">
                <strong className="card_posted mb_postdate">Posted:</strong>{" "}
                <span className="cardpara2 text-black">
                  {" "}
                  <strong>{formatDate(record?.created)}</strong>
                </span>
              </p>
            </div>
            <div>
              <p className="cardMain_date mb_closehide m-0">
                <strong className="text-danger">Closing:</strong>
                <span>
                  {" "}
                  <strong className="dated text-black">
                    {formatClosingDate(record?.occupanycDate)}
                  </strong>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="row wasandnow_price">
          {/* <div className="now_main">
            {" "}
            <p className="card_price2 was_mb">
              <span className="Was_hide">Was</span>{" "}
              <span className="card_price2 cutwas_price">
                {record?.assignmentPrice && (
                  <> ${record?.assignmentPrice?.toLocaleString("en-US")}</>
                )}
              </span>
              <span className="waspricee_sqft mx-2">{`$${WasSqftPrice}/SQft`}</span>
            </p>
          </div> */}
          {record?.assignmentPrices?.length > 0 ? (
            <div className="now_main">
              {" "}
              <p className="card_price2 was_mb">
                {/* <span className="Was_hide">Was</span>{" "} */}
                <span className="card_price2 cutwas_price">
                  <> ${record?.assignmentPrice?.toLocaleString("en-US")}</>
                </span>
                <span className="waspricee_sqft mx-2">{`$${WasSqftPrice.toLocaleString(
                  "en-US"
                )}/SQft`}</span>
              </p>
            </div>
          ) : null}
          <div className="now_main">
            <p className="card_now m-0 mt-1">
              {/* <span className="Was_hide">Now</span> */}
              <span>
                {record?.assignmentPrice && (
                  <> ${nowAssignmentPrice?.toLocaleString("en-US")}</>
                )}
              </span>
              <span className="nowpricee_sqft mx-2">{`$${NowSqftPrice.toLocaleString(
                "en-US"
              )}/SQft`}</span>
            </p>
          </div>
        </div>
        <div className="col-12">
          <p className="card_project m-0">
            <strong>{record?.projectName && <>{record?.projectName}</>}</strong>
          </p>
        </div>
        <div className="row">
          <div className="col-12 giveMeEllipsis1">
            {/* {record?.developer && (
              <>
                <span className="cname">{record?.developer.slice(0,30)}</span>
              </>
            )} */}
            {/* {record?.address && (
              <>
                {" "}
                | <span className="iconfiller cname">{record?.address}</span>
              </>
            )} */}
            {record?.address && (
              <>
                {" "}
                <span className="iconfiller cname">
                  {record?.city
                    ? record?.address.substring(0, 35) +
                      (record?.address.length > 35 ? "..." : "")
                    : record?.address}
                </span>{" "}
                {record?.city && (
                  <>
                    <span
                      className="iconfiller cname"
                      style={{ color: "black" }}
                    >
                      |
                    </span>{" "}
                    <span className="iconfiller cname">{record?.city}</span>
                  </>
                )}
              </>
            )}
          </div>
        </div> 
        <Typography
          className="mt-1 cardmain_highlight"
          variant="body2"
          color="text.secondary"
        >
          {record?.highlight && (
            <>
              {record?.highlight?.length > 80 ? (
                <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${record?.highlight?.slice(0, 80)}`,
                  }}
                  />
                  <span className="card_view"> ...View More </span>
                  </>
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: record?.highlight,
                  }}
                />
              )}
            </>
          )}
        </Typography>
      </CardContent>
    </div>
  );
}
