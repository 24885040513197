import React, { useEffect, useRef, useState } from "react";
import "@fontsource/open-sans";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import { Link } from "react-scroll";
import baseURL from "../../config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import Navbar from "../Navbar/Navbar";
import {
  BedIcon,
  PerSqftIcon,
  SqftIcon,
  BuildingIcon,
  PropertyCommuteIcon,
  EditIcon,
  ScrollArrowIcon,
  BathIcon,
  WatchSoldIcon,
  WatchingNeighbourIcon,
} from "../../SvgIcons/Icons";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import PropertyHeader from "../PropertyDetails/SubComponents/PropertyHeader";
import PropertyImages from "./SubComponents/PropertyImages";
import MarketActivity from "../PropertyDetails/SubComponents/MarketActivity";
import PropertyHighlights from "../PropertyDetails/SubComponents/PropertyHighlights";
import KeyFacts from "../PropertyDetails/SubComponents/KeyFacts";
import ExploreProperty from "../PropertyDetails/SubComponents/ExploreProperty/Index";
import MortgageCalculator from "../PropertyDetails/SubComponents/MortgageCalculator";
import Footer from "../Footer/Footer";
import FindNearby from "../PropertyDetails/SubComponents/FindNearby";
import PropertyNotes from "../PropertyDetails/SubComponents/PropertyNotes";
import PropertyAgentCard from "../PropertyDetails/SubComponents/PropertyAgentCard";
import GetAlertCard from "../PropertyDetails/SubComponents/GetAlertCard";
import QueryCard from "../PropertyDetails/SubComponents/QueryCard";

//Modals import
import ImagesModal from "../PropertyDetails/SubComponents/Modals/ImagesModal";
import AgentQueryModal from "../PropertyDetails/SubComponents/Modals/AgentQueryModal";
import RatingModal from "../PropertyDetails/SubComponents/Modals/RatingModal";
import WatchNeigbourHoodModal from "../PropertyDetails/SubComponents/Modals/WatchNeighbourHoodModal";
import SignUpModal from "../Modals/SignUpModal";
import ListingSoldModal from "../PropertyDetails/SubComponents/Modals/ListingSoldModal";
import CommuteModal from "../PropertyDetails/SubComponents/Modals/CommuteModal";
import EmailConfirmationModal from "../Modals/EmaiConfirmationModal";
import { Helmet } from "react-helmet";
import AssignmentPricing from "../PropertyDetails/SubComponents/AssignmentPricing";
import {
  addRatingData,
  removeRatingData,
} from "../../ReduxStore/Slices/RatingDataSlice";
import PropertyFaqs from "../PropertyDetails/SubComponents/PropertyFaqs";
import { PropertyData } from "../PropertyDetails/PropertData";

// import PopularSearches from "../PropertyDetails/SubComponents/PopularSearches";
// import WhyBuyProperty from "../PropertyDetails/SubComponents/WhyBuyProperty";
// import AboutProperty from "../PropertyDetails/SubComponents/AboutProperty";
// import Amenities from "../PropertyDetails/SubComponents/Amenities";
// import Rooms from "../PropertyDetails/SubComponents/Rooms";
// import NearbyCities from "../PropertyDetails/SubComponents/NearByCities";
// import Incentives from "../PropertyDetails/SubComponents/Incentives";
// import FeatureAndFinishes from "../PropertyDetails/SubComponents/FeatureAndFinishes";
// import MaintenanceFee from "../PropertyDetails/SubComponents/MaintenanceFee";
// import HomeValue from "../PropertyDetails/SubComponents/HomeValue";
// import ValuesAndTrends from "../PropertyDetails/SubComponents/ValuesAndTrends";
// import Demographics from "../PropertyDetails/SubComponents/Demographics";
// import HouseHoldComposition from "../PropertyDetails/SubComponents/HouseHoldComposition";
// import PropertyTypeDistribution from "../PropertyDetails/SubComponents/PropertyTypeDistribution";
// import AreaStatistics from "../PropertyDetails/SubComponents/AreaStatistics";
// import ListingComparable from "../PropertyDetails/SubComponents/ListingComparable";
// import PropertyFaqs from "../PropertyDetails/SubComponents/PropertyFaqs";
// import BasicDetails from "../PropertyDetails/SubComponents/BasicDetails";
// import PropertyComment from "../PropertyDetails/SubComponents/PropertyComment";
// import FeaturedListing from "../PropertyDetails/SubComponents/FeaturedListing";
// import EnvironmentFactor from "../PropertyDetails/SubComponents/EnvironmentFactor";
// import AreaFactor from "../PropertyDetails/SubComponents/AreaFactor";
// import Transits from "../PropertyDetails/SubComponents/Transits";

const AssignmentDetails = () => {
  const sections = [
    { id: "highlights", label: "Highlights" },
    { id: "keyFacts", label: "Key Facts" },
    { id: "calculator", label: "Mortgage calculator" },
  ];

  const cookies = new Cookies();
  const isUserlogin = cookies.get("jwt_authorization");

  const originurls = window.location.pathname;
  const pathParts = originurls.split("/");
  const PropertySlug = pathParts[pathParts.length - 1];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lastScrollTopRef = useRef(0);
  const userData = useSelector((state) => state.userProfileDataSlice);
  const monthlyPayemnt = useSelector(
    (state) => state.MonthlyMortgagePaymentSlice
  );

  const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
  const encryptIV = process.env.REACT_APP_ENCRYPT_IV;

  const [isLoading, setIsLoading] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [data, setData] = useState([]);
  const [purchasePrice, setPurchasePrice] = useState("");
  const [isCalculate, setIsCalculate] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [mainTitle, setMainTitle] = useState("AssignmentSold.ca");
  const [title, setTitle] = useState("AssignmentSold.ca");
  const [description, setDescription] = useState(
    "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales."
  );
  const [keywords, setKeywords] = useState(
    "assignment sold, assignment sale, condo, townhouse"
  );
  const [seoShareImage, setSeoShareImage] = useState([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isNeighborhoodModalOpen, setIsNeighborhoodModalOpen] = useState(false);
  const [isNotifyWhenSoldModalOpen, setIsNotifyWhenSoldModalOpen] =
    useState(false);
  const [isNotifyLoading, setIsNotifyLoading] = useState(false);
  const [isNotified, setIsNotified] = useState(false);
  const [isWatchingNeighbour, setIsWatchingNeighbour] = useState(false);
  const [isCommuteModalOpen, setIsCommuteModalOpen] = useState(false);
  const [isEmailConfirmedModal, setIsEmailConfirmedModal] = useState(false);
  const [signUpModalHeading, setSignUpModalHeading] =
    useState("Create an account");

  //Hide or Show Navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTopRef.current && !isScrollingDown) {
        setIsScrollingDown(true);
      } else if (
        currentScrollTop < lastScrollTopRef.current &&
        isScrollingDown
      ) {
        setIsScrollingDown(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingDown]);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function decryptData(encryptedData) {
    const keyUtf8 = CryptoJS.enc.Base64.parse(encryptKey);
    const ivUtf8 = CryptoJS.enc.Base64.parse(encryptIV);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, keyUtf8, {
      iv: ivUtf8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // Utility function to convert keys to camelCase
  function lowercaseFirstLetter(obj) {
    if (obj !== null && typeof obj === "object") {
      const newObj = Array.isArray(obj) ? [] : {};
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const newKey = key.charAt(0).toLowerCase() + key.slice(1);
          newObj[newKey] = lowercaseFirstLetter(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  }
  //Fetch Property Data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/Assignment/GetAssignment/${PropertySlug}`,
        {
          headers: {
            Authorization: `Bearer ${isUserlogin}`,
          },
        }
      );
      if (response?.data?.result === 1) {
        try {
          const decryptedJsonData = JSON.parse(
            decryptData(response?.data?.data, encryptKey, encryptIV)
          );
          const formatedKeysData = lowercaseFirstLetter(decryptedJsonData);
          setData(formatedKeysData);
          const alreadyNotified =
            formatedKeysData?.listingUserActivityDTO?.notifySold;
          if (alreadyNotified) {
            setIsNotified(alreadyNotified?.uId);
          }
          const watchingNeighbour =
            formatedKeysData?.listingUserActivityDTO?.watchNeighbour;
          setIsWatchingNeighbour(
            watchingNeighbour?.delisted ||
              watchingNeighbour?.soldListing ||
              watchingNeighbour?.newListing
          );
          localStorage.setItem("isNewListing", watchingNeighbour?.newListing);
          localStorage.setItem("isSoldListing", watchingNeighbour?.soldListing);
          localStorage.setItem(
            "isDelistedListing",
            watchingNeighbour?.delisted
          );
          dispatch(removeRatingData());
          const ratingData = formatedKeysData?.ratePropertys?.map((item) => ({
            id: item?.id,
            content: item?.content,
            values: item?.values,
          }));
          dispatch(addRatingData(ratingData));
          setIsLoading(false);
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("There is no data for this Assignment.");
        navigate("/");
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
      navigate("/");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line
  }, [PropertySlug]);

  const nowAssignmentPrice =
    data?.assignmentPrices?.length > 0
      ? data?.assignmentPrices[data?.assignmentPrices?.length - 1].prices
      : data?.assignmentPrice;
  const NowSqftPrice = Math.ceil(nowAssignmentPrice / data?.interiorSize);
  const isPriceDrop = data?.assignmentPrice - nowAssignmentPrice;

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  //bed Value Work
  const [bedValue, setBedValue] = useState();

  useEffect(() => {
    if (data?.bed === "0B") {
      setBedValue("Studio");
    } else if (data?.bed === "1B") {
      setBedValue("1 Bed");
    } else if (data?.bed === "1B+D") {
      setBedValue("1 Bed + D");
    } else if (data?.bed === "2B") {
      setBedValue("2 Bed");
    } else if (data?.bed === "2B+D") {
      setBedValue("2 Bed + D");
    } else if (data?.bed === "3B") {
      setBedValue("3 Bed");
    } else if (data?.bed === "3B+D") {
      setBedValue("3 Bed+D");
    } else if (data?.bed === "4B") {
      setBedValue("4 Bed");
    } else if (data?.bed === "4B+D") {
      setBedValue("4 Bed+D");
    } else if (data?.bed === "5B") {
      setBedValue("5 Bed");
    } else if (data?.bed === "5B+D") {
      setBedValue("5 Bed+D");
    } else if (data?.bed === "6B") {
      setBedValue("6 Bed");
    } else if (data?.bed === "6B+D") {
      setBedValue("6 Bed+D");
    }

    //Seo Work
    if (data?.assignmentSeos) {
      const metaseosTags = data?.assignmentSeos[0]?.metaSeos;
      const mainTitleObject = metaseosTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "main_Title"
      );
      const titleMain = mainTitleObject
        ? mainTitleObject?.content
        : "AssignmentSold.ca";
      setMainTitle(titleMain);
      const titleObject = metaseosTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "title"
      );
      const pageTitle = titleObject
        ? titleObject?.content
        : "AssignmentSold.ca";
      setTitle(pageTitle);
      const descriptionObject = metaseosTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "description"
      );
      const pageDescription = descriptionObject
        ? descriptionObject?.content
        : "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.";
      setDescription(pageDescription);
      const KeywordObject = metaseosTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "keywords"
      );
      const pageKeywords = KeywordObject
        ? KeywordObject?.content
        : "assignment sold, assignment sale, condo, townhouse";
      setKeywords(pageKeywords);

      const MediaCategoryData = data?.assignmentMedias?.filter(
        (obj) => obj?.mediaCategory !== "Floor Plan"
      );
      setSeoShareImage(MediaCategoryData);
    }
    setPurchasePrice(nowAssignmentPrice);
    // eslint-disable-next-line
  }, [data]);

  //Seo Work
  const currentUrl = window.location.href;

  const handleSignUpModalClose = () => {
    setIsSignUpModalOpen(false);
    setSignUpModalHeading("Create an account");
  };

  const handleInfoModalClick = () => {
    setIsInfoModalOpen(true);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleRatingModalClose = () => {
    setRatingModalOpen(false);
  };

  const handleWatchNeighborHoodClick = () => {
    if (isUserlogin) {
      setIsNeighborhoodModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to view nearby Listings!");
    }
  };

  const handleNeighbourHoodModalClose = () => {
    setIsNeighborhoodModalOpen(false);
  };

  const handleNotifyWhenSoldClick = async (uId) => {
    if (!isUserlogin || !userData) {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to view sold Listings!");
      return;
    }
    if (isNotifyLoading) {
      return;
    }
    setIsNotifyLoading(true);
    if (userData[0]?.emailConfirmed) {
      if (isNotified) {
        DeletefromNotified();
      } else {
        AddToNotify();
      }
    } else {
      navigate("/email-confirmation?isEmailConfirmed=false");
    }
  };

  const AddToNotify = async () => {
    try {
      const addData = {
        Id: "0",
        ProId: data?.uId,
      };

      const headers = {
        Authorization: `Bearer ${isUserlogin}`,
      };
      const formData = new FormData();
      formData.append("AddNotifyRequest", JSON.stringify(addData));
      const response = await axios.post(
        `${baseURL}/api/AuthData/AddNotify`,
        addData,
        { headers }
      );

      if (response.data.result === 1) {
        setIsNotified(response?.data?.data?.uId);
        setIsNotifyLoading(false);
        toast.success("You will receive an email when the property is sold");
      } else {
        toast.error("Try Again!");
        setIsNotifyLoading(false);
      }
    } catch (error) {
      toast.error("Try Again!");
      setIsNotifyLoading(false);
    }
  };

  const DeletefromNotified = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${isUserlogin}`,
        },
      };

      const response = await axios.delete(
        `${baseURL}/api/AuthData/DeleteNotify/${isNotified}`,
        config
      );

      if (response.data.result === 1) {
        setIsNotified(false);
        setIsNotifyLoading(false);
      } else {
        toast.error("Delete Again!");
        setIsNotifyLoading(false);
      }
    } catch (error) {
      toast.error("Delete Again!");
      setIsNotifyLoading(false);
    }
  };

  const handleNotifyWhenSoldModalClose = () => {
    setIsNotifyWhenSoldModalOpen(false);
  };

  const handleCommuteModalClick = () => {
    if (isUserlogin) {
      setIsCommuteModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to navigate Your Commute!");
    }
  };

  const handleEmailConfirmedModalClose = () => {
    setIsEmailConfirmedModal(false);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
  };

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${data?.latitude},${data?.longitude}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={currentUrl} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={
            seoShareImage?.length === 0
              ? "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
              : `${baseURL}/${seoShareImage[0]?.fileUrl?.replace(/\\/g, "/")}`
          }
        />
        <meta property="og:url" content={currentUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={
            seoShareImage?.length === 0
              ? "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
              : `${baseURL}/${seoShareImage[0]?.fileUrl?.replace(/\\/g, "/")}`
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:url" content={currentUrl} />
      </Helmet>

      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: isScrollingDown ? 100 : 1100,
          transition: "all ease .4s",
        }}
      >
        <Navbar />
      </div>
      {isLoading ? (
        <div className="container mt-3">
          <Skeleton animation="wave" variant="rounded" height={300} />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: "1.5rem" }}
          />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: "1rem" }} />
        </div>
      ) : (
        <>
          <PropertyHeader
            data={data}
            isUserlogin={isUserlogin}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
            handleWatchNeighborHoodClick={handleWatchNeighborHoodClick}
            handleNotifyWhenSoldClick={handleNotifyWhenSoldClick}
            isNotifyLoading={isNotifyLoading}
            handleInfoModalClick={handleInfoModalClick}
            handleCommuteModalClick={handleCommuteModalClick}
            isMobileView={isMobileView}
            isScrollingDown={isScrollingDown}
            isNotified={isNotified}
            isWatchingNeighbour={isWatchingNeighbour}
            setIsCalculate={setIsCalculate}
          />
          <div className="container Propert_details_main_container">
            <div className="">
              <PropertyImages
                setImageModalOpen={setImageModalOpen}
                data={data?.assignmentMedias}
                projectName={data?.projectName}
                setActiveSlide={setActiveSlide}
              />
            </div>

            <div className="row mt-4">
              <div className="col-lg-8 col-12">
                <div className="FloorPlan_main_information Assignment_main_information">
                  <div className="Prop_assignment_contentDetails">
                    {data?.propStatus ? (
                      <div
                        className="PropertyStatusBadge PropertyDetails_Mbl_f14"
                        style={{
                          background:
                            data?.propStatus !== "Sold" ? "#D1FADF" : "#FFDFC3",
                          color:
                            data?.propStatus !== "Sold" ? "#008733" : "#BC300A",
                        }}
                      >
                        <span
                          style={{
                            background:
                              data?.propStatus !== "Sold"
                                ? "#008733"
                                : "#BC300A",
                          }}
                        ></span>
                        {data?.propStatus}
                      </div>
                    ) : null}

                    {data?.projectName ? (
                      <h1 className="mt-2 mb-0">{data?.projectName}</h1>
                    ) : null}

                    <div className="d-flex align-items-center flex-wrap gap-2">
                      {nowAssignmentPrice ? (
                        <h1 className="m-0">{`$${nowAssignmentPrice?.toLocaleString(
                          "en-US"
                        )}`}</h1>
                      ) : null}
                      {isPriceDrop ? (
                        <p
                          className="prop_show_downgrade m-0 d-flex align-items-center"
                          style={{
                            fontWeight: "600",
                            color: "#008733",
                          }}
                        >
                          <ScrollArrowIcon color={"#008733"} size={24} />
                          <span>{`$${isPriceDrop?.toLocaleString(
                            "en-Us"
                          )}`}</span>
                        </p>
                      ) : null}
                      <p className="description PropertyDetails_Mbl_f14 mt-2 mb-0">
                        {" "}
                        Est. ${monthlyPayemnt}/mo{" "}
                        <span style={{ cursor: "pointer" }}>
                          <Link
                            spy={true}
                            activeClass="active"
                            offset={-100}
                            smooth={true}
                            duration={500}
                            to={"calculator"}
                            onClick={() => setIsCalculate(true)}
                          >
                            <EditIcon />
                          </Link>
                        </span>
                      </p>
                    </div>

                    {data?.address ? (
                      <p className="description PropertyDetails_Mbl_f14 mb-0 mt-2">
                        {data.address.includes(",")
                          ? data.address.split(",")[0]
                          : data.address}
                      </p>
                    ) : null}

                    {data?.city ? (
                      <p className="description PropertyDetails_Mbl_f14 m-0">
                        {`${data.city}${
                          data?.region ? `, ${data.region}` : ""
                        }${data?.postalcode ? `, ${data.postalcode}` : ""}`}
                      </p>
                    ) : null}
                    {data?.id ? (
                      <p className="description PropertyDetails_Mbl_f14 mb-0 mt-3">
                        ID: <strong className="fw-bold">{data?.id}</strong>{" "}
                      </p>
                    ) : null}

                    <div className="Prop_assignmentAddress">
                      {data?.created ? (
                        <p className="PropertyDetails_Mbl_f14 m-0">
                          Post date:{" "}
                          <strong>{formatDate(data?.created)}</strong>
                        </p>
                      ) : null}
                      {data?.occupanycDate ? (
                        <p className="PropertyDetails_Mbl_f14 m-0">
                          Occupancy date:{" "}
                          <strong>{formatDate(data?.occupanycDate)}</strong>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {data?.developer ? (
                  <div
                    className={`Prop_assignmentAddress ${
                      isMobileView ? "mt-2" : "mt-3"
                    }`}
                  >
                    <p className="PropertyDetails_Mbl_f14 Propertydeveloper_name">
                      Developer: <strong>{data?.developer}</strong>{" "}
                    </p>
                  </div>
                ) : null}

                <div className="d-flex flex-wrap property_information_DetailsIcon mt-4">
                  <p>
                    <BedIcon /> <span>{bedValue}</span>
                  </p>
                  {data?.bath ? (
                    <p>
                      <BathIcon color={"#282828"} />{" "}
                      <span>{data?.bath} Bath</span>
                    </p>
                  ) : null}
                  {data?.type ? (
                    <p>
                      <BuildingIcon /> <span>{data?.type}</span>
                    </p>
                  ) : null}
                  {data?.interiorSize ? (
                    <p>
                      <SqftIcon />{" "}
                      <span>
                        {data?.interiorSize?.toLocaleString("en-US")} SqFt
                      </span>
                    </p>
                  ) : null}
                  {NowSqftPrice ? (
                    <p>
                      <PerSqftIcon />{" "}
                      <span>
                        {`$ ${Math.ceil(NowSqftPrice)?.toLocaleString(
                          "en-US"
                        )}/SqFt`}
                      </span>
                    </p>
                  ) : null}
                </div>

                <div className="Property_detailsWeb_hide w-100 mt-3 prop_details_lgShow mx-0">
                  <img
                    src="/images/mapDirection.png"
                    alt="MapLocationImg"
                    loading="lazy"
                    className="Find_nearByMobileMapImg mt-3"
                    onClick={openGoogleMaps}
                  />
                  <div className="d-flex flex-wrap w-100 gap-2 mt-3">
                    <button
                      className="Mbl_notifyBtn Mbl_notifyBtn_bluetbtn"
                      onClick={handleCommuteModalClick}
                    >
                      <span
                        className="DetailsIcon_greyBg"
                        style={{
                          background: "var(--Primary-Natural-blue, #007BFF)",
                        }}
                      >
                        <PropertyCommuteIcon color={"#fff"} />
                      </span>
                      <span>Add a commute</span>
                    </button>
                    <button
                      onClick={handleWatchNeighborHoodClick}
                      className="Mbl_notifyBtn Propmbl_f11"
                    >
                      <span
                        className={`DetailsIcon_greyBg ${
                          isWatchingNeighbour ? "DetailsIcon_LightBg" : ""
                        }`}
                      >
                        <WatchingNeighbourIcon
                          color={isWatchingNeighbour ? "#007BFF" : "#282828"}
                          size={"24"}
                        />
                      </span>
                      Watching Neighbourhood
                    </button>
                    <button
                      disabled={isNotifyLoading}
                      onClick={handleNotifyWhenSoldClick}
                      className="Mbl_notifyBtn"
                    >
                      <span
                        className={`DetailsIcon_greyBg ${
                          isNotified ? "DetailsIcon_LightBg" : ""
                        }`}
                      >
                        <WatchSoldIcon
                          color={isNotified ? "#007BFF" : "#282828"}
                          size={"24"}
                        />
                      </span>
                      Notify when Sold{" "}
                    </button>
                  </div>
                </div>

                {!isMobileView ? (
                  <hr
                    className="mt-5"
                    style={{
                      backgroundColor: "#8d8c8c",
                      height: "1px",
                      width: "100%",
                      border: "none",
                    }}
                  />
                ) : null}

                <div className="mt-5">
                  <div id="marketActivity">
                    <MarketActivity data={data} isMobileView={isMobileView} />
                  </div>
                  {data?.highlight && (
                    <div id="highlights" className="Prop_details_CompMargin">
                      <PropertyHighlights
                        data={data?.highlight}
                        projectName={data.projectName}
                        isMobileView={isMobileView}
                      />
                    </div>
                  )}
                  {/* <div id="propertyDetails" className="Prop_details_CompMargin">
                    <BasicDetails isMobileView={isMobileView} />
                  </div> */}
                  <div id="keyFacts" className="Prop_details_CompMargin">
                    <KeyFacts isMobileView={isMobileView} data={data} />
                  </div>
                  {/* <div id="rooms" className="Prop_details_CompMargin">
                    <Rooms isMobileView={isMobileView} />
                  </div>
                  <div
                    id="featureAndFinishes"
                    className="Prop_details_CompMargin"
                  >
                    <FeatureAndFinishes isMobileView={isMobileView} />
                  </div>
                  <div id="amenities" className="Prop_details_CompMargin">
                    <Amenities isMobileView={isMobileView} />
                  </div>
                  <div
                    id="includeInMaintanceFee"
                    className="Prop_details_CompMargin"
                  >
                    <MaintenanceFee isMobileView={isMobileView} />
                  </div>
                  <div id="reasonToBuy" className="Prop_details_CompMargin">
                    <WhyBuyProperty isMobileView={isMobileView} />
                  </div>
                  <div id="incentives" className="Prop_details_CompMargin">
                    <Incentives isMobileView={isMobileView} />
                  </div> */}
                  <div id="calculator" className="Prop_details_CompMargin">
                    <MortgageCalculator
                      purchasePrice={purchasePrice}
                      setPurchasePrice={setPurchasePrice}
                      isMobileView={isMobileView}
                      isCalculate={isCalculate}
                      setIsCalculate={setIsCalculate}
                    />
                  </div>
                  <div id="pricing" className="Prop_details_CompMargin">
                    <AssignmentPricing
                      sellerAskingPrice={nowAssignmentPrice}
                      isMobileView={isMobileView}
                      isCalculate={isCalculate}
                      setIsCalculate={setIsCalculate}
                      data={data}
                      purchasePrice={purchasePrice}
                      setPurchasePrice={setPurchasePrice}
                    />
                  </div>
                  {data?.longitude && data?.latitude ? (
                    <div
                      id="exploreProperty"
                      className="Prop_details_CompMargin"
                    >
                      <ExploreProperty
                        data={data}
                        isMobileView={isMobileView}
                      />
                    </div>
                  ) : null}
                  {/* <div id="homeValue" className="PropDetails_BorderNoneMargin">
                    <HomeValue isMobileView={isMobileView} />
                  </div>
                  <div id="valueAndTrends" className="Prop_details_CompMargin">
                    <ValuesAndTrends isMobileView={isMobileView} />
                  </div>
                  <div
                    id="exploreProperty"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <Transits isMobileView={isMobileView} />
                  </div>
                  <div
                    id="exploreProperty"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <AreaFactor isMobileView={isMobileView} />
                  </div>
                  <div
                    id="exploreProperty"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <EnvironmentFactor isMobileView={isMobileView} />
                  </div>
                  <div
                    id="demographics"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <Demographics isMobileView={isMobileView} />
                  </div>
                  <div
                    id="householdComposition"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <HouseHoldComposition isMobileView={isMobileView} />
                  </div>
                  <div
                    id="propertyTypeDistribution"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <PropertyTypeDistribution isMobileView={isMobileView} />
                  </div>
                  <div
                    id="areaStatistics"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <AreaStatistics isMobileView={isMobileView} />
                  </div>
                  <div
                    id="featuredListing"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <FeaturedListing isMobileView={isMobileView} />
                  </div>
                  <div
                    id="compareListing"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <ListingComparable
                      setIsSignUpModalOpen={setIsSignUpModalOpen}
                      setSignUpModalHeading={setSignUpModalHeading}
                      isUserlogin={isUserlogin}
                      isMobileView={isMobileView}
                    />
                  </div> 
                  */}
                  <div
                    id="propertyFaqs"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <PropertyFaqs data={PropertyData?.FAQs}/>
                  </div>
                  <div
                    id="queryCard"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <QueryCard handleInfoModalClick={handleInfoModalClick} />
                  </div>
                  {/* <div
                    id="propertyComment"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <PropertyComment />
                  </div>
                  <div
                    id="aboutProperty"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <AboutProperty isMobileView={isMobileView} />
                  </div>
                  <div id="popularSearches" className="Prop_details_CompMargin">
                    <PopularSearches isMobileView={isMobileView} />
                  </div>
                  <div id="nearByCities" className="Prop_details_CompMargin">
                    <NearbyCities isMobileView={isMobileView} />
                  </div> */}
                </div>
              </div>

              {!isMobileView && (
                <div className="col-lg-4 col-12 PropDetailsMob_lg_hide">
                  <div>
                    <FindNearby
                      longitude={data?.longitude}
                      latitude={data?.latitude}
                      handleCommuteModalClick={handleCommuteModalClick}
                    />
                  </div>

                  <div className="mt-3">
                    <GetAlertCard
                      handleWatchNeighborHoodClick={
                        handleWatchNeighborHoodClick
                      }
                      handleNotifyWhenSoldClick={handleNotifyWhenSoldClick}
                      isNotifyLoading={isNotifyLoading}
                      isWatchingNeighbour={isWatchingNeighbour}
                      isNotified={isNotified}
                    />
                  </div>

                  <div
                    className={`position-sticky ${
                      isScrollingDown
                        ? "Stick_headerOnly"
                        : "prop_represent_linkStatic"
                    }  `}
                  >
                    <div className="mt-3">
                      <PropertyAgentCard
                        handleInfoModalClick={handleInfoModalClick}
                      />
                    </div>

                    <div>
                      <PropertyNotes
                        data={data}
                        setIsSignUpModalOpen={setIsSignUpModalOpen}
                        isUserlogin={isUserlogin}
                        setSignUpModalHeading={setSignUpModalHeading}
                      />
                    </div>

                    <div className="Prop_details_section_box mt-3">
                      <ul>
                        {sections?.map((section, index) => (
                          <li key={index}>
                            <Link
                              spy={true}
                              activeClass="active"
                              offset={-100}
                              smooth={true}
                              duration={500}
                              to={section.id}
                            >
                              {section.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isSignUpModalOpen && (
              <SignUpModal
                open={isSignUpModalOpen}
                handleClose={handleSignUpModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setIsEmailConfirmedModal={setIsEmailConfirmedModal}
                heading={signUpModalHeading}
              />
            )}
            {isInfoModalOpen && (
              <AgentQueryModal
                data={data}
                open={isInfoModalOpen}
                handleClose={handleInfoModalClose}
              />
            )}

            {ratingModalOpen && (
              <RatingModal
                data={data}
                open={ratingModalOpen}
                handleClose={handleRatingModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
                isUserlogin={isUserlogin}
              />
            )}

            {isNeighborhoodModalOpen && (
              <WatchNeigbourHoodModal
                data={data}
                isUserlogin={isUserlogin}
                open={isNeighborhoodModalOpen}
                handleClose={handleNeighbourHoodModalClose}
                setIsWatchingNeighbour={setIsWatchingNeighbour}
                isWatchingNeighbour={isWatchingNeighbour}
              />
            )}

            {isNotifyWhenSoldModalOpen && (
              <ListingSoldModal
                open={isNotifyWhenSoldModalOpen}
                handleClose={handleNotifyWhenSoldModalClose}
              />
            )}

            {isCommuteModalOpen && (
              <CommuteModal
                latitude={data?.latitude}
                longitude={data?.longitude}
                open={isCommuteModalOpen}
                setIsCommuteModalOpen={setIsCommuteModalOpen}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
              />
            )}

            {isEmailConfirmedModal && (
              <EmailConfirmationModal
                open={isEmailConfirmedModal}
                handleClose={handleEmailConfirmedModalClose}
              />
            )}

            {imageModalOpen && (
              <ImagesModal
                open={imageModalOpen}
                handleClose={handleImageModalClose}
                projectName={data?.projectName}
                data={data?.assignmentMedias}
                activeSlide={activeSlide}
              />
            )}
          </div>

          <div className="Prop_detailsMobileFixedBtn prop_details_BtnShow Property_detailsBtn_hide">
            <button
              className="propertyDetails_moreInfo PropDetails_gradientBtn"
              onClick={handleInfoModalClick}
            >
              Request for more info
            </button>
            <button
              className="propertyDetails_moreInfo NoteBtn"
              onClick={() => {
                if (isUserlogin) {
                  setRatingModalOpen(true);
                } else {
                  setIsSignUpModalOpen(true);
                  setSignUpModalHeading(
                    "Become a member to access & Write Research Notes."
                  );
                }
              }}
            >
              Research Note
            </button>
          </div>
        </>
      )}

      <div style={{ marginTop: "6rem" }}>
        <Footer />
      </div>

      <ToastContainer />
    </>
  );
};

export default AssignmentDetails;
