import Marquees from "./SubComponents/Marquee/Marquees";
import CallAgent from "../Components/ContactAgent/CallAgent";
import HeaderSection from "./SubComponents/HeaderSection/HeaderSection";
import Footer from "../Components/Footer/Footer";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import HelmetLayout from "../Utils/HelmetLayout/Helmet";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import ListingCarousel from "../Components/PropertyDetails/SubComponents/ListingComparable/Carousel";
import "./home.css";
import NotifyFunnels from "./SubComponents/NotifyFunnels";
import OurMission from "./SubComponents/OurMission";
import InsightAndTrends from "./SubComponents/InsightAndTrends";
import Articles from "./SubComponents/Articles";
import AssignHomeSteps from "./SubComponents/AssignHomeSteps";
import AssignmentSoldIntro from "./SubComponents/AssignmentSoldIntro";
import SearchingSuggestion from "./SubComponents/SearchingSuggestion";
import axios from "axios";
import baseURL from "../config";
import FunnelModal from "./SubComponents/FunnelsModal";

const Home = () => {
  const adminData = useSelector((state) => state.adminSiteDataSlice);
  const webHighlight = adminData[0]?.highlight;
  const [categoryLocation, setCategoryLocation] = useState();
  const [categoryBType, setCategoryBType] = useState();
  const [listingData, setListingData] = useState([]);
  const [listingLoading, setListingLoading] = useState(true);
  const OPTIONS = { slidesToScroll: "auto", align: "start", skipSnaps: true };
  const [isFunnelModalOpen, setIsFunnelModalOpen] = useState(false);

  const popularSearches = [
    {
      label: "Condo in Toronto",
      query: "Toronto",
      building: "Condo",
    },
    {
      label: "TownHouse in Toronto",
      query: "Toronto",
      building: "TownHouse",
    },
    {
      label: "Detached in Toronto",
      query: "Toronto",
      building: "Detached",
    },
    {
      label: "Condo in Mississauga",
      query: "Mississauga",
      building: "Condo",
    },
    {
      label: "TownHouse in Mississauga",
      query: "Mississauga",
      building: "TownHouse",
    },
    {
      label: "Detached in Mississauga",
      query: "Mississauga",
      building: "Detached",
    },
    {
      label: "Condo in Vaughan",
      query: "Vaughan",
      building: "Condo",
    },
    {
      label: "TownHouse in Vaughan",
      query: "Vaughan",
      building: "TownHouse",
    },
    {
      label: "Detached in Vaughan",
      query: "Vaughan",
      building: "Detached",
    },
  ];

  const nearBycities = [
    {
      label: "Assignments in Toronto",
      query: "Toronto",
    },
    {
      label: "Assignments in Mississauga",
      query: "Mississauga",
    },
    {
      label: "Assignments in Vaughan",
      query: "Vaughan",
    },
    {
      label: "Assignments in Oakville",
      query: "Oakville",
    },
    {
      label: "Assignments in North York",
      query: "North York",
    },
    {
      label: "Assignments in Scarborough",
      query: "Scarborough",
    },
    {
      label: "Assignments in Markham",
      query: "Markham",
    },
    {
      label: "Assignments in Brampton",
      query: "Brampton",
    },
    {
      label: "Assignments in Milton",
      query: "Milton",
    },
  ];

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchLsitingData = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/data/GetHomeAssignment`);
      if (response?.data?.result === 1) {
        setListingData(response?.data?.data);
      }
    } catch (e) {
      console.error("error", e);
    } finally {
      setListingLoading(false);
    }
  };

  useEffect(() => {
    fetchLsitingData();
  }, []);

  return (
    <>
      <HelmetLayout pageName={"Home-page"} />

      <div style={{ position: "sticky", top: 0, zIndex: 1100 }}>
        {/* <Navbar navBarRef={navBarRef}/> */}
      </div>
      {/* <div className="container heading_logo">
        <Heading />
      </div> */}
      {webHighlight && <Marquees webHighlight={webHighlight} />}

      <div>
        <div className={isMobileView ? "container p-1 " : "container p-3"}>
          <div>
            <CallAgent isMobileView={isMobileView} />
          </div>
          <HeaderSection
            categoryLocation={categoryLocation}
            setCategoryLocation={setCategoryLocation}
            categoryBType={categoryBType}
            setCategoryBType={setCategoryBType}
          />
        </div>

        {/* New Listing Component */}

        <div className="container">
          <div className="mt-3">
            <h2 className="FeaturedListings__Headings">New Listings</h2>
            <div className="mt-3">
              <ListingCarousel
                slides={listingData?.newAssignments}
                options={OPTIONS}
                isLoading={listingLoading}
                isMobileView={isMobileView}
              />
            </div>
          </div>

          <div className="mt-3">
            <h2 className="FeaturedListings__Headings">Recently Sold</h2>
            <div className="mt-3">
              <ListingCarousel
                slides={listingData?.soldAssignments}
                options={OPTIONS}
                isLoading={listingLoading}
                isMobileView={isMobileView}
              />
            </div>
          </div>

          <div className="mt-3">
            <h2 className="FeaturedListings__Headings">Price Change</h2>
            <div className="mt-3">
              <ListingCarousel
                slides={listingData?.priceAssignments}
                options={OPTIONS}
                isLoading={listingLoading}
                isMobileView={isMobileView}
              />
            </div>
          </div>
        </div>

        {/* Notify Funnels */}
        <div className="mt-3">
          <NotifyFunnels />
        </div>

        {/* Our Mission */}
        <OurMission />

        {/* Insights And Trends */}
        {/* <InsightAndTrends /> */}

        {/* AssignHomeStep */}
        <AssignHomeSteps />

        {/* Articles Section */}
        <div className="mt-5">
          <AssignmentSoldIntro />
        </div>

        {/* Articles Section */}
        <div className="container mt-5">
          <Articles isMobileView={isMobileView} />

          {/* Popular Searches Section */}
          <div className="mt-5">
            <SearchingSuggestion
              heading={"Popular Searches"}
              supportingLine={
                "Find what others are looking for—explore the most sought-after properties and trends."
              }
              data={popularSearches}
            />
          </div>

          {/* NearBy cities Section */}
          <div className="mt-5">
            <SearchingSuggestion
              heading={"Nearby Cities"}
              supportingLine={
                "Discover nearby cities and expand your search for the perfect property."
              }
              data={nearBycities}
            />
          </div>
        </div>

        {/* ------- SellProperty  Section ---------- */}
        <section className="d-flex mt-4 m-v-footer text-white container-fluid justify-content-between greencolor p-4">
          <div className="me-5 ">
            <h2 className="mar ft-11 font">
              Do You want to Sell Your Property?
            </h2>
          </div>
          <div className="me-5 ">
            <NavLink to="/sell" className="textcolor">
              <button className="btn font fbton ">
                List Your Property Now
              </button>
            </NavLink>
          </div>
        </section>

        <div style={{ marginTop: "4rem" }}>
          <Footer />
        </div>

        {isFunnelModalOpen && (
          <FunnelModal
            data={"test@gmail.com"}
            setIsFunnelModalOpen={setIsFunnelModalOpen}
            isFunnelModalOpen={isFunnelModalOpen}
          />
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default Home;
