import React from "react";
import { ArticleNextReadIcon } from "../../../../SvgIcons/Icons";
import { useNavigate } from "react-router-dom";
import baseURL from "../../../../config";

const ArticleCard = ({ data, isMainCard }) => {
  const navigate = useNavigate();

  const formatDate = (date) => {
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  function extractTextFromHTML(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ");
  }

  return (
    <div
      className={`w-100 home_articleCard ${
        isMainCard ? "home_articleMainCard" : ""
      }`}
    >
      <img
        src={`${baseURL}/${data?.blogMedias[0]?.fileUrl}`}
        alt="article-card"
        loading="lazy"
        className="w-100"
        style={{ height: isMainCard ? "400px" : "200px" }}
      />
      <p className="postDate">{formatDate(data?.created)}</p>
      <div
        className="d-flex justify-content-between align-items-start gap-2"
        onClick={() => navigate(`/blogs/details/${data?.slug}`)}
      >
        <h4 className="title">{data?.title}</h4>
        <button className="border-0 bg-transparent">
          <ArticleNextReadIcon />
        </button>
      </div>
      <p className="description">{extractTextFromHTML(data?.des)}</p>
      <div className="d-flex flex-wrap gap-3">
        <p className="chip">Blog</p>
        <p className="chip category_chip">Article</p>
        <p className="chip read_chip">{`${data?.readtime} min read`}</p>
      </div>
    </div>
  );
};

export default ArticleCard;
