import React, { useEffect, useState } from "react";
import baseURL from "../../../../config";
import { useLocation } from "react-router-dom";
import PropertyFaqs from "../../../../Components/PropertyDetails/SubComponents/PropertyFaqs";
import axios from "axios";

const ListingSeo = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("query");
  const searchType = params.get("searchtype");
  const [citySeoData, setCitySeoData] = useState([]);

  const fetchCityDescription = async (cityName) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/GetCityDetail?term=${cityName}`
      );
      if (response?.data?.result === 1) {
        setCitySeoData(response?.data?.data);
        // setMetaSeoTags(response?.data?.data?.citySeo[0]?.metaSeos);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (search && searchType === "Location") fetchCityDescription(search);
    // eslint-disable-next-line
  }, [search]);


  return (
    <>
      {search &&
        searchType === "Location" &&
        citySeoData?.cityDescription?.length > 0 && (
      <p dangerouslySetInnerHTML={{ __html: citySeoData?.cityDescription}} />
        )}

      {search &&
        searchType === "Location" &&
        citySeoData?.cityFaqs?.length > 0 && (
          <div className="px-3 pt-5 listings_faqs">
            <PropertyFaqs data={citySeoData?.cityFaqs} isShowHeading={true} />
          </div>
        )}
    </>
  );
};

export default ListingSeo;
