import axios from 'axios';

const getAddressFromMapbox = async (latitude, longitude) => {
  try {
    const accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;

    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`
    );

    if (response.data.features.length > 0) {
      return response.data.features[0].place_name;
    } else {
      return 'Address not found';
    }
  } catch (error) {
    return 'Geocoding request failed';
  }
};

const Geolocation = async () => {
  return new Promise(async (resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;

          if (userLatitude && userLongitude) {
            const address = await getAddressFromMapbox(userLatitude, userLongitude);
            resolve({ latitude: userLatitude, longitude: userLongitude, address });
          } else {
            reject('Unable to determine the user\'s location.');
          }
        },
        (error) => {
          reject(`Error: ${error.message}`);
        }
      );
    } else {
      reject('Geolocation is not available in your browser.');
    }
  });
};

export default Geolocation;
