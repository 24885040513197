import React, { useEffect, useState } from "react";

import ArticleCard from "./SubComponents/ArticleCard";
import ArticleSlider from "./SubComponents/ArticleSlider";
import axios from "axios";
import baseURL from "../../../config";

const Articles = ({ isMobileView }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const OPTIONS = { slidesToScroll: "auto" };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/blog/featured`);
      setData(response?.data?.data);
    } catch (error) {
      console.error("Error Fetching Blogs");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="home_blogs">
        <h2>Blogs</h2>
        <p>
          AssignmentSold has authored over{" "}
          <span style={{ color: "#0253A0", fontWeight: "bold" }}>100</span>{" "}
          articles on topics like sales performance, customer service, community
          impact, marketing innovation, leadership, and team collaboration. His
          writings showcase his industry expertise and dedication to sharing
          valuable insights with clients and peers alike
        </p>
      </div>
      {!isMobileView && !loading && (
        <div className="w-100 mt-3">
          <ArticleCard data={data[0]}  isMainCard={true} />
        </div>
      )}
      <div className="w-100 mt-4">
        <ArticleSlider loading={loading} isMobileView={isMobileView} data={data} options={OPTIONS} />
      </div>
    </>
  );
};

export default Articles;
