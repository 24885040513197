import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Cookies from "universal-cookie";
import { HashLink as Link } from "react-router-hash-link";

import jwt from "jwt-decode";
import { useDispatch } from "react-redux";
import { removeItem } from "../../ReduxStore/Slices/LoginSlice";
import { removeProfileData } from "../../ReduxStore/Slices/ProfileDataSlice";
import "./Navbar.css";
import { AiOutlineUser } from "react-icons/ai";
// import { BsFillFlagFill } from "react-icons/bs";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Badge from "@mui/material/Badge";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import baseURL from "../../config";
import axios from "axios";
import { addUserProfileData } from "../../ReduxStore/Slices/UserProfileData";
import { removeUserProfileData } from "../../ReduxStore/Slices/UserProfileData";
import { addAdminWebData } from "../../ReduxStore/Slices/AdminSiteDataSlice";
import { setFavItems } from "../../ReduxStore/Slices/HeartDataSlice";
import { BiUserCircle } from "react-icons/bi";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import jwtDecode from "jwt-decode";
const theme = createTheme({
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "white",
          color: "red",
        },
      },
    },
  },
});
export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const heartRating = useSelector((state) => state.heartDataSlice);
  const profileData = useSelector((state) => state.profileDataSlice);
  const userDataSlice = useSelector((state) => state.userProfileDataSlice[0]);
  const userData = useSelector((state) => state.profileDataSlice[0]?.data);
  const isLogedIn = localStorage.getItem("anonymous");
  const isUserAuthorize = jwt_token && isLogedIn === "1";
  let isEmailConfirmend = true;
  let UserRole = "";
  if (jwt_token) {
    let token = cookies.get("jwt_authorization");
    const decode = jwtDecode(token);
    UserRole =
      decode["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    const emailConfirmedStr =
      decode["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous"];
    const emailConfirmed = emailConfirmedStr === "True";
    if (emailConfirmed === false || profileData?.emailConfirmed === false)
      isEmailConfirmend = false;
  }

  React.useEffect(() => {
    const fetchDataFromLocalStorage = () => {
      const existingData =
        JSON.parse(localStorage.getItem("bulkTrackingData")) || [];
      return existingData;
    };

    const saveDataToLocalStorage = (data) => {
      localStorage.setItem("bulkTrackingData", JSON.stringify(data));
    };

    const trackVariable = localStorage.getItem("trackVariable") || "0";

    if (isUserAuthorize) {
      const existingData = fetchDataFromLocalStorage();
      // Map data to the format required by your API
      const mappedData = existingData.map((dataItem) => ({
        id: dataItem.id,
        userId: userData?.id,
        projectName: dataItem.projectName,
        slug: dataItem.slug,
      }));

      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };

      const apiUrl =
        trackVariable === "0"
          ? `${baseURL}/api/data/AddActivityTrackedbulk`
          : `${baseURL}/api/data/AddActivityTracked`;

      axios
        .post(apiUrl, mappedData, { headers })
        .then((response) => {
          localStorage.setItem("trackVariable", "1");
          saveDataToLocalStorage([]);
        })
        .catch((error) => {
          localStorage.setItem("trackVariable", "1");
          console.error("Error posting data to API", error);
        });
    }
    // eslint-disable-next-line
  }, [isUserAuthorize]);

  useEffect(() => {
    axios
      .get(`${baseURL}/api/Admin/GetWebHighlight`)
      .then((response) => {
        dispatch(addAdminWebData(response?.data?.data));
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line
  }, []);

  const fetchCurrentUserData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const response = await axios.get(
        `${baseURL}/api/User-Data/GetCurrentUser`,
        { headers }
      );
      const data = response?.data?.data;
      dispatch(addUserProfileData(data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isUserAuthorize) {
      fetchCurrentUserData();
    }
    // eslint-disable-next-line
  }, [isUserAuthorize]);

  const isActivePage = () => {
    const ids = [
      "justReached",
      "hotDeals",
      "fastMoving",
      "priceChange",
      "HighlightAdd",
    ];
    return ids.some((id) => window.location.pathname.includes(id));
  };

  const handleLogout = () => {
    localStorage.removeItem("fullName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("offer");
    localStorage.setItem("trackVariable", "0");
    cookies.remove("jwt_authorization", { path: "/" });
    sessionStorage.setItem("ChatId", "");
    dispatch(removeItem());
    dispatch(removeProfileData());
    localStorage.setItem("anonymous", 0);
    dispatch(removeUserProfileData());
    // navigate("/")
    window.location.reload();
  };

  const favAndSav = (url) => {
    const returnUrl = url;
    if (isUserAuthorize) {
      navigate(url);
    } else {
      navigate("/login", { state: { returnUrl: returnUrl } });
    }
  };

  const handleLogin = () => {
    if (isUserAuthorize && profileData.length > 0) {
      const jwt_token = profileData[0].data.refreshToken;
      const exp_time = profileData[0].data.refreshTokenExpiryTime;
      // eslint-disable-next-line
      const decoded = jwt(jwt_token);
      const refreshTokenExpiry = new Date(exp_time);
      cookies.set("jwt_authorization", jwt_token, {
        expires: refreshTokenExpiry,
        path: "/",
      });
      navigate("/");
    } else {
      navigate("/login", { state: { returnUrl: window.location.pathname } });
    }
  };
  // eslint-disable-next-line
  const [favouriteByUser, setFavouriteByUser] = useState([]);

  const fetchFavourites = async () => {
    try {
      const favouritesResponse = await axios.get(
        `${baseURL}/api/User-Data/GetFavouriteByUser`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      setFavouriteByUser(favouritesResponse?.data?.data);
      if (favouritesResponse?.data?.result === 1) {
        const favItems = favouritesResponse?.data?.data.map((item) => ({
          uId: item?.proId,
          tableUId: item?.uId,
        }));
        dispatch(setFavItems(favItems));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const badgeContent = heartRating?.favItems?.length;

  useEffect(() => {
    if (isUserAuthorize) {
      fetchFavourites();
    }
    // eslint-disable-next-line
  }, []);

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      style={{ maxHeight: "100%", overflowY: "auto" }}
      className=""
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="d-flex justify-content-between align-items-center  pb-2 pt-2">
        <div className="align-items-center ps-3" style={{ cursor: "pointer" }}>
          <CloseIcon />
        </div>

        <div className="d-flex">
          <NavLink to="/">
            <img
              className="navimgclasser"
              src={require("../../images/Logo_FOR_MOBILE.png")}
              alt="AssignmentSold Logo"
              loading="lazy"
            />
          </NavLink>
        </div>
        <div className="mv-icon pt-2 pe-3 d-flex">
          <div className="d-flex">
            {isUserAuthorize ? (
              <div>
                <img
                  className="pr-img"
                  src={
                    userDataSlice?.imageUrl
                      ? userDataSlice.imageUrl.startsWith(
                          "https://lh3.googleusercontent.com"
                        )
                        ? userDataSlice.imageUrl
                        : `${baseURL}/${userDataSlice.imageUrl}`
                      : require("../../images/ProfileAvatar.jpg")
                  }
                  alt="User Profile"
                  loading="lazy"
                />
              </div>
            ) : (
              <div>
                <BiUserCircle className="mv-i-clas" onClick={handleLogin} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="navborders"></div>
      <div className="pt-3 mx-3">
        <div className={isUserAuthorize ? "grey__background d-flex" : "d-none"}>
          <div className="p-2">
            <img
              className="profile__Image"
              src={
                userDataSlice?.imageUrl
                  ? userDataSlice.imageUrl.startsWith(
                      "https://lh3.googleusercontent.com"
                    )
                    ? userDataSlice.imageUrl // Google image URL
                    : `${baseURL}/${userDataSlice.imageUrl}` // Backend image URL
                  : require("../../images/ProfileAvatar.jpg") // Default image if no URL is provided
              }
              alt="User Profile"
              loading="lazy"
            />
          </div>
          <div className="pl-2 d-flex justify-content-center w-100 f-d-c">
            <p className="m-0 name__Heading font ">
              {userDataSlice?.firstName}
            </p>
            <p className="m-0 font mobileFont14">{userDataSlice?.roles[0]}</p>
          </div>
        </div>
      </div>
      <div
        className={
          isUserAuthorize
            ? "grey__background p-2 mt-5 mx-3"
            : "grey__background p-2 mx-3 mt-5"
        }
      >
        <NavLink to={"/"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            {" "}
            Buy
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink>
        <NavLink to={"/sell"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            {" "}
            Sell
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink>
        <NavLink to={"/find-deals/justReached"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            {" "}
            Find Deals
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink>

        <NavLink to={"/how-it-works"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            How It Works
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink>
        <NavLink to={"/mortgage-advisor"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            Find an Agent
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink>

        <div
          onClick={() => favAndSav("/dashboard/favourite")}
          className=" mt-2 p-1 align-items-center d-flex justify-content-between"
          style={{ cursor: "pointer" }}
        >
          {" "}
          Favourite
          <ArrowForwardIosIcon className="forward__arrow" />
        </div>

        <div
          onClick={() => favAndSav("/dashboard/saved-search")}
          className=" mt-2 p-1 align-items-center d-flex justify-content-between"
          style={{ cursor: "pointer" }}
        >
          {" "}
          Saved Searches
          <ArrowForwardIosIcon className="forward__arrow" />
        </div>

        <Link smooth to="/sell#pricing" className="nav-link">
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            Pricing
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </Link>
        <NavLink to={"/contact-us"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            Contact Us
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink>

        {/* <NavLink to={"/dashboard/assignments-listing"}>
          <div className=" mt-2 p-1 align-items-center d-flex justify-content-between">
            My Listings
            <ArrowForwardIosIcon className="forward__arrow" />
          </div>
        </NavLink> */}
      </div>
      {isUserAuthorize ? (
        <div className="pt-5 mx-3 my-2">
          <div
            onClick={handleLogout}
            className="w-100 grey__background p-2"
            style={{ cursor: "pointer" }}
          >
            <LogoutIcon /> Log out
          </div>
        </div>
      ) : (
        <div className="pt-5 mx-3  my-2 ">
          <div
            onClick={handleLogin}
            className="w-100 grey__background py-3 px-3"
            style={{ cursor: "pointer", background: "#02549e", color: "#fff" }}
          >
            <AiOutlineUser /> Login
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="font">
      <nav className="navbar p-0  navbar-expand-lg navcolor pt-1">
        <div className="container m-v-c0n">
          <div className="m-v-img-to-hide">
            {" "}
            <NavLink to="/">
              <img
                className="navimgclass"
                src={require("../Images/Web_logo.png")}
                alt="AssignmentSold Logo"
                loading="lazy"
              />
            </NavLink>
          </div>
          <div className="buttons">
            <div className="m-v-item-to-show item_show_at_12 w-100">
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button className="p-0 " onClick={toggleDrawer(anchor, true)}>
                    {" "}
                    <MenuIcon className="Menu__Icon" />
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className="navbar_drawer_content"
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse  "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  centering ms-auto mb-2 mb-lg-0">
                <li className="nav-item marginright">
                  <NavLink to="/" className="nav-link" aria-current="page">
                    Buy
                  </NavLink>
                </li>
                <li className="nav-item marginright">
                  <NavLink
                    className="nav-link"
                    // to={jwt_token ? "/SubmitAssignment" : "/Login"}
                    to={"/Sell"}
                  >
                    Sell
                  </NavLink>
                </li>
                <li className="nav-item marginright">
                  <NavLink
                    className={`nav-link ${isActivePage() ? "active" : ""}`}
                    // to={jwt_token ? "/find-deals" : "/Login"}
                    to={"/find-deals/justReached"}
                  >
                    Find Deals
                  </NavLink>
                </li>
                <li className="nav-item marginright">
                  <NavLink
                    className="nav-link"
                    // to={jwt_token ? "/how-it-works" : "/Login"}
                    to={"/how-it-works"}
                  >
                    How it Works
                  </NavLink>
                </li>
                <li className="nav-item marginright">
                  <NavLink
                    className="nav-link"
                    // to={jwt_token ? "/SearchAgent" : "/Login"}
                    to={"/mortgage-advisor"}
                  >
                    Find an Agent
                  </NavLink>
                </li>
                <li className="nav-item marginright">
                  <Link smooth to="/sell#pricing" className="nav-link">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item marginright">
                  <NavLink
                    className="nav-link"
                    // to={jwt_token ? "/contact-us" : "/Login"}
                    to={"/contact-us"}
                  >
                    Contact us
                  </NavLink>
                </li>
              </ul>

              <div className="heartmargin">
                <NavLink
                  to={
                    isUserAuthorize
                      ? isEmailConfirmend
                        ? "/dashboard/favourite"
                        : "/email-confirmation?isEmailConfirmed=false"
                      : "/Login"
                  }
                >
                  <ThemeProvider theme={theme}>
                    <Badge badgeContent={badgeContent} color="primary">
                      <FavoriteIcon sx={{ color: "red", fontSize: "24px" }} />
                    </Badge>
                  </ThemeProvider>
                </NavLink>
              </div>
              {isUserAuthorize ? (
                <ul className="navbar-nav ms-auto  mb-2 mb-lg-0">
                  <li className="nav-item    dropdown">
                    <button
                      className="nav-link dropdown-toggle btn-link"
                      // role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <IoIosArrowDown className="down-icon" />

                      <img
                        className="pr-img"
                        src={
                          userDataSlice?.imageUrl
                            ? userDataSlice.imageUrl.startsWith(
                                "https://lh3.googleusercontent.com"
                              )
                              ? userDataSlice.imageUrl // Google image URL
                              : `${baseURL}/${userDataSlice.imageUrl}` // Backend image URL
                            : require("../../images/ProfileAvatar.jpg") // Default image if no URL is provided
                        }
                        alt="User Profile"
                        loading="lazy"
                      />
                    </button>

                    <ul
                      className="dropdown-menu  "
                      style={{
                        position: " absolute",
                        inset: "0px 0px auto auto",
                        transform: "translate(-18px, 54px)",
                      }}
                    >
                      <li className="">
                        <NavLink className="nonedecor" to="/dashboard/profile">
                          <button className="dropdown-item">Profile</button>
                        </NavLink>
                      </li>
                      {UserRole === "Seller" ||
                      UserRole === "Staff" ||
                      UserRole === "Buyer" ||
                      UserRole === "Realtor" ? (
                        <li className="">
                          <NavLink
                            className="nonedecor"
                            to="/dashboard/favourite"
                          >
                            <button className="dropdown-item">
                              Favourites
                            </button>
                          </NavLink>
                        </li>
                      ) : null}
                      {UserRole === "Seller" ||
                      UserRole === "Staff" ||
                      UserRole === "Buyer" ||
                      UserRole === "Realtor" ? (
                        <li className="">
                          <NavLink
                            className="nonedecor"
                            to="/dashboard/saved-search"
                          >
                            <button className="dropdown-item">
                              Saved Searches
                            </button>
                          </NavLink>
                        </li>
                      ) : null}
                      {UserRole === "Seller" ||
                      UserRole === "Staff" ||
                      UserRole === "Realtor" ||
                      UserRole === "Marketing" ? (
                        <li className="">
                          <NavLink
                            className="nonedecor"
                            to="/dashboard/assignments-listing/Active"
                          >
                            <button className="dropdown-item">Listings</button>
                          </NavLink>
                        </li>
                      ) : null}
                      {UserRole === "Seller" ||
                      UserRole === "Staff" ||
                      UserRole === "Realtor" ? (
                        <li className="">
                          <NavLink
                            className="nonedecor"
                            to="/dashboard/order-summary/Active"
                          >
                            <button className="dropdown-item">My order</button>
                          </NavLink>
                        </li>
                      ) : null}
                      {/* <li className="">
                        <NavLink className="nonedecor" to="/dashboard/Messages">
                          <button className="dropdown-item">Meages</button>
                        </NavLink>
                      </li> */}

                      <li className="">
                        <NavLink className="nonedecor" to="/dashboard/Contacts">
                          <button className="dropdown-item">Contacts</button>
                        </NavLink>
                      </li>
                      <li>
                        <button
                          onClick={handleLogout}
                          className="dropdown-item"
                        >
                          Log out
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <button onClick={handleLogin} className="btn m-0 bton">
                  <AiOutlineUser /> Login
                </button>
              )}

              <div className="major"></div>

              {/*  */}
            </div>
          </div>
          <div className="d-t-s-m-v">
            <div className="left__PADding">
              <NavLink to="/">
                <img
                  className="navimgclasser"
                  src={require("../../images/Logo_FOR_MOBILE.png")}
                  alt="AssignmentSold Logo"
                  loading="lazy"
                />
              </NavLink>
            </div>
          </div>
          <div className="mv-icon  d-flex">
            <div className="d-flex">
              {isUserAuthorize ? (
                <NavLink to="/dashboard/profile">
                  <div className="">
                    <img
                      className="pr-img"
                      src={
                        userDataSlice?.imageUrl
                          ? userDataSlice.imageUrl.startsWith(
                              "https://lh3.googleusercontent.com"
                            )
                            ? userDataSlice.imageUrl // Google image URL
                            : `${baseURL}/${userDataSlice.imageUrl}` // Backend image URL
                          : require("../../images/ProfileAvatar.jpg") // Default image if no URL is provided
                      }
                      alt="User Profile"
                      loading="lazy"
                    />
                  </div>
                </NavLink>
              ) : (
                <BiUserCircle
                  style={{ cursor: "pointer" }}
                  className="mv-i-clas"
                  onClick={handleLogin}
                />
              )}
            </div>
          </div>
        </div>
      </nav>

      <div className="navborders"></div>
    </div>
  );
}
